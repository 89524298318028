@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  @font-face {
    font-family: Montserrat;
    src: url("./fonts/montserrat/Montserrat-VariableFont_wght.ttf") format("truetype");
  }

}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box
}

input[type=text] {
  background-color: #FFF;
}
input[type=number] {
  background-color: #FFF;
}
input[type=email] {
  background-color: #FFF;
}
input[type=password] {
  background-color: #FFF;
}

body {
  font-family: "TTI"
}

::-webkit-scrollbar {display:none;}

input:focus {
  border: "1px solid red" !important;
}


.carousel-container {
  margin: 50px 0 40px;
}
.carousel {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* height: 60vh; */
}

.carousel-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;
}
.carousel-header .underline {
  height: 10px;
  border-radius: 6px;
  width: 170px;
  margin-bottom: 24px;
  background: var(--color-secondary);
}
.carousel-header > h3 {
  font-size: 1.8rem;
  margin-bottom: 2px;
  font-family: "Edu NSW ACT Foundation", cursive;
}

.carousel .control-dots {
  
  text-align: left !important;
  padding-left:9% !important;
  padding-right: 30px !important
}
.carousel .control-dots .dot {
  box-shadow: none !important;
  /* background-color: #E3E1E1 !important; */
  background-color: transparent !important;
  opacity: 1 !important;
}

.carousel .control-dots .dot.selected {
  opacity: 1 !important;
  /* background-color: #395AB5 !important; */
  background-color: transparent !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #395AB5 !important;
}